import { IconCopy } from "@tabler/icons-react";
import * as React from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../atoms/TextInput/TextInput";
import { Body, IconButton, Label, Root } from "./CopyToClipboardField.styles";

interface IProps {
    readonly value: string | undefined;
    readonly label?: string;
    readonly readonly?: boolean;
    readonly placeholder?: string;
}

export const CopyToClipboardField: React.FC<React.PropsWithChildren<IProps>> = ({ value, label, readonly = true, placeholder }) => {
    const [t] = useTranslation();
    const copyToClipboard = useCallback(async () => {
        await navigator.clipboard.writeText(value ?? "");
    }, [value]);

    return (
        <Root>
            {label !== undefined && <Label>{label}</Label>}
            <Body>
                <TextInput type="text" value={value} placeholder={placeholder} disabled={readonly} />
                <IconButton onClick={copyToClipboard} aria-label={t("components.clipboard")}>
                    <IconCopy />
                </IconButton>
            </Body>
        </Root>
    );
};
