import { TelemetryEvent } from "@headbot/library";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useContext } from "react";
import { IFileInput } from "../../../components/atoms/FileUploader/FileUploader";
import { AlertsContext, AlertType, IAlert } from "../../../components/molecules/Alerts/AlertsContext";
import { getAxiosRequestConfig } from "../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../services/Constants/Constants";
import { telemetry } from "../../../services/Telemetry/Telemetry";
import { useAuth } from "../../useAuth";
import { QueryCacheKey } from "../QueryCacheKey";

interface IResponse {}

interface IMutationParams {
    readonly file: IFileInput;
    readonly jobId: string;
}

const getMutationQuery = (token: string | null) => {
    const postRequest = async (params: IMutationParams): Promise<IResponse> => {
        const { file, jobId } = params;
        const config = getAxiosRequestConfig(token, { type: "multipart/form-data" });
        const { data } = file;
        const bodyFormData = new FormData();
        bodyFormData.append("data", data, file.name);
        const request = axios.post(`${Constants.ApiRoot}/job/${jobId}/file`, bodyFormData, config);

        const { data: response } = await request;
        return response;
    };
    return postRequest;
};

export const usePostJobFile = (onSuccess: (response: IResponse) => void) => {
    const { pushAlert } = useContext(AlertsContext);
    const queryClient = useQueryClient();
    const { authToken } = useAuth();

    const mutation = useMutation(getMutationQuery(authToken), {
        onMutate: () => {
            telemetry.Log(TelemetryEvent.UploadJobFileInit, null);
        },
        onSuccess: (data) => {
            telemetry.Log(TelemetryEvent.UploadJobFileSuccess, null);
            queryClient.invalidateQueries([QueryCacheKey.Job]);
            onSuccess(data);
        },
        onError: (error, _variables, _context) => {
            const e = error as AxiosError<any>;
            const submitError = JSON.stringify(e.response?.data?.error);
            const alert: IAlert = {
                type: AlertType.Error,
                message: submitError,
                timeout: Constants.AlertModalTimeoutMs,
            };
            pushAlert?.(alert);
            telemetry.Log(TelemetryEvent.UploadJobFileError, submitError);
        },
    });
    return { mutation };
};
