import { IconFileCheck } from "@tabler/icons-react";
import * as React from "react";
import { P } from "../../../atoms/P/P";
import { Color } from "../../../layout/GlobalStyles/Color";
import { UploadedFileContainer } from "./JobDetails.styles";

interface IProps {
    readonly filename: string;
}

export const UploadedFile: React.FC<IProps> = ({ filename }) => {
    return (
        <UploadedFileContainer>
            <IconFileCheck size={28} strokeWidth={2} color={Color.Green100} />
            <P style={{ marginLeft: 5 }}>{filename}</P>
        </UploadedFileContainer>
    );
};
