import styled from "styled-components";
import { Dimensions } from "../../../layout/GlobalStyles/Dimensions";

export const Root = styled.div`
    padding-top: ${Dimensions.GridUnit * 5}px;
    padding-bottom: ${Dimensions.GridUnit * 5}px;
    padding-left: ${Dimensions.GridUnit * 2}px;
    padding-right: ${Dimensions.GridUnit * 2}px;
`;

export const UploadedPhotosContainer = styled.div`
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: ${Dimensions.GridUnit * 20}px;
    margin-top: ${Dimensions.GridUnit * 3}px;
    margin-bottom: ${Dimensions.GridUnit * 3}px;
`;

export const CenteredJobsPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const UploadedFileContainer = styled.div`
    display: flex;
    align-items: center;
`;
